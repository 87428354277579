<template>
  <v-container class="grey lighten-5 mb-6" fluid>
    <v-row>
      <v-col class="main-col-margin">
        <v-card class="mx-auto">
          <v-card-title
            class="main-card-title-offset"
          >
            <v-icon large left color="white"> mdi-format-list-bulleted </v-icon>
            <span class="title">{{ $t('sector.list.title') }}</span>
            <v-spacer></v-spacer>

            <v-btn icon @click="createSector()">
              <v-icon color="white">mdi-plus-circle-outline</v-icon>
            </v-btn>
          </v-card-title>

          <v-divider></v-divider>

          <v-data-table
            :headers="headers"
            :items="sectors"
            :sort-by="[]"
            :sort-desc="[false, true]"
            multi-sort
            class="elevation-1"
            :loading="isLoading"
            :loading-text="$t('sector.list.loading')"
          >
            <template v-slot:[`item.image_path`]="props">
              <v-img :src="props.item.image_path" class="sector-datatable-image "></v-img>
            </template>

            <template v-slot:[`item.translations`]="props">
              <span v-for="translations in props.item.translations" :key="translations.id">
                <flag :iso="languages[translations.language].flag" class="translation-flags"/>
              </span>
            </template>

            <template v-slot:[`item.controls`]="props">
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="warning"
                @click="editSector(props.item)"
              >
                <v-icon dark>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="error"
                @click="openDeleteSectorDialog(props.item)"
              >
                <v-icon dark>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title class="headline">{{ $t('sector.list.delete.title') }}</v-card-title>
          <v-card-text>
            {{ $t('sector.list.delete.description') }}
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-row class="text-center" style="margin: 0px">
              <v-col>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="error"
                  text
                  @click="deleteSector()"
                >
                  <v-icon dark>mdi-delete</v-icon>
                  {{ $t('sector.list.delete.controlls.delete') }}
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="primary"
                  text
                  @click="closeDeleteSectorDialog()"
                >
                  <v-icon dark>mdi-close-circle</v-icon>
                  {{ $t('sector.list.delete.controlls.cancel') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <error-handler :error="error"></error-handler>
  </v-container>
</template>
<script>
export default {
  name: "ListSectors",
  data() {
    return {
      error: null,
      isLoading: true,
      headers: [
        { text: this.$t('sector.list.label.internalName'), align: "start", value: "internal_name", sortable: true },
        { text: this.$t('sector.list.label.image'), value: "image_path", sortable: false },
        { text: this.$t('sector.list.label.translations'), value: "translations", sortable: false },
        {
          text: this.$t('sector.list.label.actions'),
          value: "controls",
          sortable: false,
          align: "center",
        },
      ],
      sectors: [],
      dialog: false,
      deletingSector: null,
    };
  },
  computed: {
    applicationData() {
      return this.$store.state.applicationData
    },
    languages() {
      if(this.applicationData) {
        return this.applicationData.crm_languages; 
      }
      return []
    }
  },
  mounted() {
    this.$store.dispatch('loadApplicationData')
    this.fetchSectors();
  },
  methods: {
    fetchSectors() {
      this.$axios({
        method: "get",
        url: this.$globals.api.sector.getAll,
      })
        .then((response) => {
          this.sectors = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = error.response;
        });
    },
    createSector() {
      this.$router.push({ name: "sector/add" });
    },
    editSector(sector) {
      this.$router.push({ name: "sector/edit", params: { id: sector.id } });
    },
    openDeleteSectorDialog(sector) {
      this.dialog = true;
      this.deletingSector = sector;
    },
    closeDeleteSectorDialog() {
      this.dialog = false;
      this.deletingSector = null;
    },
    deleteSector() {
      this.$axios({
        method: "post",
        url: this.$globals.api.sector.delete + this.deletingSector.id,
      })
        .then(() => {
          this.deletingSector = null;
          this.dialog = false;
          this.isLoading = true;
          this.fetchSectors();
        })
        .catch((error) => {
          this.error = error.response;
        });
    },
  },
};
</script>